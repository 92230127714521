
































































import { Vue, Component, Prop } from "vue-property-decorator";
import firebase from "@/plugins/firebase";
import Confirm from "@/components/Confirm.vue";

interface Category {
  id: string;
  name: string;
}

interface Course {
  id: string;
  name: string;
}

@Component<AddRemoveCategory>({
  components: {
    Confirm
  },

  watch: {
    async course() {
      await this.getAllCategories();
      await this.getSavedCategories();
    }
  }
})
export default class AddRemoveCategory extends Vue {
  @Prop() course!: Course;

  disabled = false;

  categories = [] as Category[];

  savedCategories = [] as Category[];

  selectedCategories = [] as string[];

  close() {
    this.selectedCategories = [];
    this.$emit("close");
  }

  async getAllCategories() {
    this.categories = [];

    try {
      const categoryQs = await firebase
        .firestore()
        .collection("/apps/kahev-akademi/categories")
        .orderBy("name")
        .get();

      categoryQs.forEach(catDoc => {
        this.categories.push({
          id: catDoc.id,
          name: catDoc.data().name
        });
      });
    } catch (err) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Kategoriler alınamadı."
      });

      throw new Error(`Kategoriler alınamadı. ${err}`);
    }
  }

  async getSavedCategories() {
    this.savedCategories = [];
    this.selectedCategories = [];

    try {
      const courseDoc = await firebase
        .firestore()
        .doc(`/apps/kahev-akademi/courses/${this.course.id}`)
        .get();

      if (courseDoc.exists) {
        const catIds = courseDoc.data()?.categories as string[];

        catIds.forEach(id => {
          const i = this.categories.findIndex(c => c.id == id);
          const category = this.categories[i];
          this.savedCategories.push(category);

          this.selectedCategories.push(id);
        });
      }
    } catch (err) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Kayıtlı kategoriler alınamadı."
      });

      throw new Error(`Kayıtlı kategoriler alınamadı. ${err.message}`);
    }
  }

  async addCategories() {
    const $confirm = this.$refs.confirm as InstanceType<typeof Confirm>;
    const confirmation = await $confirm.open(
      "Emin misiniz?",
      "Seçili kategorileri eklemek istediğinizden emin misiniz?",
      {
        color: "red",
        width: 360
      }
    );

    if (!confirmation) return;

    try {
      // Kategorileri kaydet
      await firebase
        .firestore()
        .doc(`/apps/kahev-akademi/courses/${this.course.id}`)
        .update({
          categories: this.selectedCategories
        });

      await this.getSavedCategories();

      // Arka planda kategorilerin eğitim sayılarını güncelle
      this.selectedCategories.forEach(cat => {
        firebase
          .firestore()
          .doc(`/apps/kahev-akademi/categories/${cat}`)
          .update({
            count: firebase.firestore.FieldValue.increment(1)
          });
      });
    } catch (err) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Kategoriler eklenemedi."
      });

      throw new Error(`Kategoriler eklenemedi. ${err.message}`);
    }
  }

  async removeCategory(categoryId: string) {
    const $confirm = this.$refs.confirm as InstanceType<typeof Confirm>;
    const confirmation = await $confirm.open(
      "Emin misiniz?",
      `Kategoriyi silmek istediğinizden emin misiniz?`,
      {
        color: "red",
        width: 360
      }
    );

    if (!confirmation) return;

    try {
      // Kategoriyi hafızadan sil
      const i = this.selectedCategories.findIndex(c => c == categoryId);
      this.selectedCategories.splice(i, 1);

      // Kategorileri kaydet
      await firebase
        .firestore()
        .doc(`/apps/kahev-akademi/courses/${this.course.id}`)
        .update({
          categories: this.selectedCategories
        });

      await this.getSavedCategories();

      // Arka planda kategorilerin eğitim sayılarını güncelle
      firebase
        .firestore()
        .doc(`/apps/kahev-akademi/categories/${categoryId}`)
        .update({
          count: firebase.firestore.FieldValue.increment(-1)
        });
    } catch (err) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Kategori silinemedi."
      });

      throw new Error(`Kategori silinemedi. ${err.message}`);
    }
  }

  async mounted() {
    await this.getAllCategories();
    await this.getSavedCategories();
  }
}
